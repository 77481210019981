import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import MainLayout from "../layout/mainLayout"

class NotFound extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.data.wordpressAcfOptions.options.not_found,
      seoData: this.props.data.wordpressPage.acf.seo_settings,
    }
  }

  render() {
    const content = this.state.content

    return (
      <MainLayout
        footerless={true}
        headerTheme="light"
        cta={true}
        seoData={this.state.seoData}
      >
        <section id="not-found">
          <div className="grid">
            <div className="not-found__content">
              <h1>404</h1>
              {content.title && (
                <h4 dangerouslySetInnerHTML={{ __html: content.title }} />
              )}
              {content.button_title && (
                <Link
                  dangerouslySetInnerHTML={{ __html: content.button_title }}
                  to="/"
                  className="btn btn--oval btn--oval--empty"
                />
              )}
            </div>
          </div>
        </section>
      </MainLayout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            not_found {
              title
              button_title
            }
          }
        }
        wordpressPage(wordpress_id: { eq: 2 }) {
          acf {
            seo_settings {
              meta_title
              meta_description
              meta_title_fb
              meta_description_fb
              meta_title_tw
              meta_description_tw
            }
          }
        }
      }
    `}
    render={data => <NotFound data={data} {...props} />}
  />
)
